import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const title = "Module 2";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "play-around-with-graphql"
    }}>{`Play around with GraphQL`}</h1>
    <p>{`We're going to use a lot of GraphQL in our app. This is your chance to play around with GraphQL using a schema just like we're going to build in our app.`}</p>
    <iframe src="https://cbvo4h8dge.execute-api.us-east-1.amazonaws.com/dev/graphql" style={{
      width: "120%",
      border: 0,
      height: "600px"
    }}></iframe>
    <p>{`If the iframe doesn't load, try disabling your browser's 3rd party cookie protection for this site, or `}<a parentName="p" {...{
        "href": "https://cbvo4h8dge.execute-api.us-east-1.amazonaws.com/dev/graphql"
      }}>{`visit the playground directly`}</a></p>
    <h2 {...{
      "id": "the-basics"
    }}>{`The basics`}</h2>
    <p>{`GraphQL works by defining the shape of what you're looking for.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`query {
  what_you_want {
    its_property
  }
}
`}</code></pre>
    <p>{`You use mutations to save data.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`mutation {
  yourMutation(prop1: "value", prop2: "value") {
    return_value
  }
}
`}</code></pre>
    <p>{`Try using the autocomplete feature and the schema definition in the playground above to complete these 4 exercises.`}</p>
    <h2 {...{
      "id": "exercises"
    }}>{`Exercises`}</h2>
    <ul>
      <li parentName="ul">{`fetch a list of all landing pages`}</li>
      <li parentName="ul">{`fetch a specific landing page`}</li>
      <li parentName="ul">{`create a new page`}</li>
      <li parentName="ul">{`update a page`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      